import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroPost from '../components/Hero/hero-post';

import styles from './about-me.module.css';
import heroImg from '../images/about-me.svg';
import ogImg from '../images/og/about-me.png';

const AboutMePage = () => (
  <Layout>
    <SEO
      title="About me"
      description="Tomek Nieżurawski is a front-end developer and in indie hacker. He loves UX and implementing non-trivial interfaces."
      ogUrl="https://tomekdev.com/about-me"
      imageUrl={ogImg}
    />
    <HeroPost gradientFrom="#A7A6CB" gradientTo="#8989BA">
      <img src={heroImg} alt="About me illustration" height="260" />
    </HeroPost>

    <div className="container">
      <h1 className="pageTitle">About me</h1>

      <section>
        <h2 className="sectionTitle">Introduction and bio</h2>

        <p>
          My name is <i>Tomasz Nieżurawski</i> but I often use a shorter version
          of my name - <i>Tomek</i>. I found it easier to pronounce for my
          coworkers. Although, I’m always impressed by people that can say
          polish „sz” digram. It may not be easy to say.
        </p>

        <p>
          I’m a 34 years old front-end developer from{' '}
          <strong>Poznań, Poland</strong>. My country is very lovely, considered
          as cheap and very safe. I encourage you to visit Poland. You’ll find
          something for yourself for sure. We can even grab a beer together if
          you happen to visit Poznań.
        </p>

        <p>
          <strong>
            Currently, I work for Phorest Salon Software as a Principal Engineer
          </strong>
          . Greetings to my Irish friends! Awesome people.
        </p>

        <p>
          <strong>I’m a full-time remote worker</strong>. It has been 9 years
          since I started working remotely and I’m very happy with that
          decision. I used to work for a variety of companies in my 12 years,
          full-time career. From startups, through middle size to corporation
          size companies. I can’t say a bad word about any of them. Now I see
          how lucky I was to meet these people and work in these organizations.{' '}
          <strong>So I worked for:</strong>
        </p>

        <ul>
          <li>
            <strong>Fibaro</strong> - on home automation when the company was
            still a small startup,
          </li>
          <li>
            <strong>IOKI</strong>, acquired by <strong>Pearson</strong> -
            education company spotted and then acquired by a giant in the
            industry,
          </li>
          <li>
            <strong>Roche</strong> - big pharma, where IT Security was a king,
          </li>
          <li>
            <strong>Brandnew</strong> - influencer marketing startup, that
            eventually ran out of money,
          </li>
          <li>
            and now <strong>Phorest</strong> - beauty and hair industry software
            maker with ambitious plans.
          </li>
        </ul>

        <p>
          Before all these, I was working part-time for ~7 years when learning
          my craft (well, I’m still learning every day). That makes me a bit of
          a dinosaur that is in the industry for the last 19 years. I think I
          read my first book about HTML in 2003 but made my first money on it in
          2005.
        </p>

        <p>
          I had a short episode where I worked in heavy industry when
          programming PLC after graduating from{' '}
          <strong>Automation and Robotics</strong>. It was cool, but one day,
          when I saw that you can now round borders and use gradients in CSS3 I
          realized that this is my path. <strong>Silly, right?</strong>
          <br />
          Well, I guess that shows true passion. So I closed the doors of
          Robotics and got back to Computer Science.
          <br />
          <a
            href="https://niezurawski.pl/resume/"
            target="_blank"
            rel="noreferrer"
          >
            Here's my resume
          </a>{' '}
          if you'd like to see it by any chance.
        </p>
      </section>

      <section>
        <h2 className="sectionTitle">Field of interests</h2>
        <p>
          I don’t want to be a fan-boy of a single technology or framework. Same
          as most of the front-end devs I have my favorite framework but I
          understand that <strong>you choose a tool for a job</strong>. Not the
          other way. So I used or use different frameworks at the time.
        </p>

        <p>
          My expertise is around <strong>Ember and React</strong> currently. I
          used to work with Angular, VueJS, and CanJS. In general, I’m in a camp
          saying that you should master Vanilla JS. So I practice it every day.
          But we don’t have to reinvent the wheel. Using a proper framework for
          a job is a skill itself.
        </p>

        <p>
          <strong>I love CSS</strong>. Really! It is somehow quite intuitive for
          me. It’s a pity that it has such a bad press.
          <br />
          I’m not a designer although I designed this blog and most of the
          layouts in my side-projects. I don’t feel confident when designing. I
          prefer coding. Anyway, I think I see a bit more than average Software
          Engineer, and my thoughts are valuable for designers. Even if, as I
          said, I’m a noob designer.
        </p>

        <p>
          Pretty much every aspect of front-end development is interesting to me
          and it’s hard to pick something you can dedicate to. With two
          exceptions - generating PDFs from HTML and Emails! The first is not
          the right tool for the job, and the second is still in 2005 when it
          comes to technology (
          <a
            href="https://nothingventured.rocks/mjml-for-email-is-what-react-native-is-for-mobile-9579612b57e8"
            rel="noreferrer"
            target="_blank"
          >
            but check my post about MJML
          </a>
          ).
        </p>
      </section>

      <section>
        <h2 className="sectionTitle">Hustling</h2>

        <p>
          Sometimes I feel like I have too many ideas. I’m guilty of trying to
          fix every world’s (or mine) problem with software…
          <br />
          …that I write.
        </p>

        <p>
          I have <Link to="/sideprojects">three promising side-projects</Link>{' '}
          that are actively developed. Hopefully, they all will start earning
          money for me.
          <br />
          The hard but also interesting and very gratifying thing about working
          on side-projects is that I have to wear different hats. There are days
          when I have to be a marketer, designer, FE-dev, backend dev. and OPS.
          Where the „manager hat” tries to prioritize everything.
        </p>

        <p>
          You can find me on{' '}
          <a
            href="https://www.indiehackers.com/tniezurawski"
            rel="noreferrer"
            target="_blank"
          >
            IndieHackers.com
          </a>{' '}
          talking about side-projects. A brief description of them is located on{' '}
          <Link to="/sideprojects">the side-projects page</Link>.
        </p>
      </section>

      <section>
        <h2 className="sectionTitle">When not coding</h2>

        <p>
          I love football. Probably playing more than watching. I play two times
          a week in two different leagues. With friends, we play together for
          ~13 years in the same team -{' '}
          <a
            href="https://www.facebook.com/klubpilkarski"
            rel="noreferrer"
            target="_blank"
          >
            Szturmowcy
          </a>{' '}
          which means <i>Stormtroopers</i>. It may, or may not be connected with
          our accuracy when shooting on the goal ;)
        </p>

        <p>
          I'm also a dad of two which is more challenging than whatever you'll
          read on this blog. One day I'll tell you what I have learned from my
          older son about front-end development.
        </p>
      </section>

      <section>
        <h2 className="sectionTitle">One message before you go</h2>

        <blockquote className={styles.motto}>
          <p>Better done than perfect</p>
          <footer>~ said someone wise</footer>
        </blockquote>
      </section>
    </div>
  </Layout>
);

export default AboutMePage;
